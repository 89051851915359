import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: '/assets/images/service/portfolio-1.jpg',
        title: 'Discrete Event Simulation',
        description: '',
        height: "250",
        ref: '/DES'
    },
    {
        icon: '/assets/images/service/portfolio-2.jpg',
        title: 'Genomics',
        description: '',
        height: "250",
        ref: '/Genomics'
    },
    {
        icon: '/assets/images/service/portfolio-3.jpg',
        title: 'Higgs Detection',
        description: '',
        height: "250",
        ref: '/higgs'
    },
    {
        icon: '/assets/images/service/portfolio-4.jpg',
        title: 'Investment Portfolio',
        description: '',
        height: "250",
        ref: '/stocks'
    },
    {
        icon: '/assets/images/service/portfolio-5.jpg',
        title: 'Physics Papers',
        description: '',
        height: "250",
        ref: '/physics'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <img height="100" src={val.icon} alt="About Images"/>
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
