import React, { Component } from "react";
import {FaGithub, FaLinkedinIn } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import { Link } from 'react-router-dom';
import Scrollspy from 'react-scrollspy';

const SocialShare = [
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/temefford'},
    {Social: <FaGithub /> , link: 'https://www.github.com/temefford'}
]

class HeaderAbs extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
        
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader () {}

    render(){

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/portfolio/tmfavicon2.png" alt="Digital Agency" height="30px" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/portfolio/tmfavicon2.png" alt="Digital Agency" height="30px" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/portfolio/tmfavicon2.png" alt="Digital Agency" height="30px" />;
        }else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/portfolio/tmfavicon2.png" alt="Digital Agency" height="30px" />;
        }else{
            logoUrl = <img src="/assets/images/portfolio/tmfavicon2.png" alt="Digital Agency" height="30px" />;
        }
        
        return(
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <nav className="mainmenunav d-lg-block ml--50">
                            <Scrollspy className="mainmenu" items={['home','about','portfolio','skills','contact']} currentClassName="is-current" offset={-200}>
                                <li><a href="\">Home</a></li>
                                <li><a href="\#about">About</a></li>
                                <li className="has-droupdown"><Link to="#pages" >Projects</Link>
                                    <ul className="submenu">
                                        <li><Link to="/DES">DES</Link></li>
                                        <li><Link to="/genomics">Genomics</Link></li>
                                        <li><Link to="/higgs">Higgs</Link></li>
                                        <li><Link to="/stocks">Investing</Link></li>
                                        <li><Link to="/physics">Grad Papers</Link></li>
                                    </ul>
                                </li>
                                <li><a href="\#skills">Skills</a></li>
                                <li><a href="\#contact">Contact</a></li>
                            </Scrollspy>
                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                        <div className="header-btn">
                            <a className="rn-button-style--2 rn-btn-dark" href="/resume">
                                <a>CV</a>
                            </a>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderAbs;