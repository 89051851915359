import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import {FaGithub} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderAbs from "../component/header/HeaderAbs";
import Footer from "../component/footer/FooterTwo";

const SocialShare = [
    {Social: <FaGithub /> , link: 'https://www.github.com/temefford/Graduate-Physics-Papers'}
]

class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Physics' />
                <HeaderAbs homeLink="/" logo="symbol-dark" color="color-black" />
                {/* Start Banner Area   */}
                <div id="banner" className="fix">
                    <div className="slider-wrapper">
                        <div className="slide personal-portfolio-slider md-layout slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--29">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={'inner left'}>    
                                            {/* {'<span>Theo Mefford</span>' ? <h1 className="title" dangerouslySetInnerHTML={{ __html: '<span>Theo Mefford</span>' }}></h1> : ''}  
                                             */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Banner Area   */}

                {/* Start TItle Details */}
                <div className="portfolio-area pt--120 bg_color--1">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--15">
                                        <h2>Some Graduate Physics Papers</h2>
                                    </div>      
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 {/* End Title Details */}

                {/* Start About Area */}
                <div id="about" className="fix">
                    <div className="about-area ptb--40  bg_color--1">
                        <div className="about-wrapper">
                            <div className="container ptb--40" >
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/physics/DLCV.jpg" alt="DLCV"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h4 className="title">Summary of Graduate Labratory Research: Quantum Memory using Pr3+:Y2SiO5</h4>
                                                <p>Quantum memory to be used in quantum repeater schemes appears to be essential
                                                    to the development of long distance quantum communication and quantum networks.
                                                    The realization of long distance quantum network capability has not yet been achieved
                                                    and the presented research makes headway towards this goal. We report progress
                                                    towards the implementation of a quantum memory in Pr3+:Y2SiO5 that has read-write
                                                    capability. Specifically, this research successfully implemented a narrowband spectral
                                                    filter into the memory scheme.
                                                </p>
                                                <div className="thumbnail">
                                                    <a className="rn-button-style--2 btn-solid" href="/assets/images/blog/QuantumMemoryDCLZ.pdf">Download</a>
                                                    <a className="pr--50"></a>
                                                    <a className="rn-button-style--2 rn-btn-dark ptb--40" href="https://github.com/temefford/Graduate-Physics-Papers/blob/main/QuantumMemoryDCLZ.pdf">
                                                            <span>View on Github</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container ptb--40 bg_color--5">
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/physics/QPCA.jpg" alt="QPCA"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h4 className="title">Quantum Principle Component Analysis</h4>
                                                <p>In this work a technique is introduced that enables the eigenvectors corresponding to the
                                                    dominant eigenvalues of an unknown state to be found exponentially faster than can be done
                                                    with current classical methods. This technique, quantum principle component analysis, could
                                                    play a crucial role in the future by providing a tool which could help in speeding up tasks such
                                                    as state discrimination, clustering and pattern recognition. Quantum component analysis
                                                    also provides a method for supervised learning and could be essential to future quantum
                                                    machine learning technology.
                                                </p>
                                                <div className="thumbnail">
                                                    <a className="rn-button-style--2 btn-solid" href="/assets/images/blog/QuantumPrincipleComponentAnalysis.pdf">Download</a>
                                                    <a className="pr--50"></a>
                                                    <a className="rn-button-style--2 rn-btn-dark ptb--40" href="https://github.com/temefford/Graduate-Physics-Papers/blob/main/QuantumPrincipleComponentAnalysis.pdf">
                                                            <span>View on Github</span>
                                                    </a>                                           
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container ptb--40" >
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/physics/qPRec.jpg" alt="qPRec"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h4 className="title">Quantum Pattern Recognition</h4>
                                                <p>At present the connectome project is far beyond practical. Quantum computers may or
                                                    may not shift the possibility that the human brain's structure can be mapped by aiding
                                                    in the automation of image analysis. Knowledge of this potentiality is crucial as it would
                                                    provide an overall perspective on whether connectome research is worth pursuing or whether
                                                    other approaches to understanding the brain are more promising and worthy of immediate
                                                    attention and resource allocation. Exponential speed-up in image processing is highly desirable and would push the
                                                    boundary of brain research by making the crucial task of automated neuron segmen-
                                                    tation feasible for large amounts of data. This research aims to look into the potential
                                                    of quantum computers to provide this capability.
                                                </p>
                                                <div className="thumbnail">
                                                    <a className="rn-button-style--2 btn-solid" href="/assets/images/blog/QuantumPatternRecognitioninApplicationtotheConnectome.pdf">Download</a>
                                                    <a className="pr--50"></a>
                                                    <a className="rn-button-style--2 rn-btn-dark ptb--40" href="https://github.com/temefford/Graduate-Physics-Papers/blob/main/QuantumPatternRecognitioninApplicationtotheConnectome.pdf">
                                                            <span>View on Github</span>
                                                    </a> 
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container ptb--40 bg_color--5" >
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/physics/qTop.jpg" alt="qTop"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h4 className="title">Topological Quantum Computing: An Introduction</h4>
                                                <p>Topological quantum computation offerrs an elegant solution to developing a fault-tolerant
                                                    quantum computer by storing information non-locally in the states of quasiparticles. Unitary
                                                    gate operations are achieved by braiding the quasiparticles and measurement is achieved through
                                                    their fusion. This paper describes the underlying principles of topological quantum computation,
                                                    exemplifies the process with a simple model, presents current topological phases of matter being
                                                    explored, and discusses several issues it faces.
                                                </p>
                                                <div className="thumbnail">
                                                    <a className="rn-button-style--2 btn-solid" href="/assets/images/blog/TopologicalQuantumComputation.pdf">Download</a>
                                                    <a className="pr--50"></a>
                                                    <a className="rn-button-style--2 rn-btn-dark ptb--40" href="https://github.com/temefford/Graduate-Physics-Papers/blob/main/TopologicalQuantumComputation.pdf">
                                                            <span>View on Github</span>
                                                    </a>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}
                
 
                                        

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
