import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let
            tab1 = "Education",
            tab2 = "Experience";
        
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a>MS in Data Science<span> - University of San Francisco</span></a> 2023
                                                </li>
                                                <li>
                                                   <a>MBA<span> - University of San Francisco</span></a> expected 2024
                                               </li>
                                               <li>
                                                   <a>MS in Physics<span> - University of Maryland</span></a> 2018
                                               </li>
                                               <li>
                                                   <a>BS in Physics/Mathematics<span> - Santa Clara University</span></a> 2013
                                                </li>
                                                <li>
                                                   <a>High School Diploma<span> - Piedmont High School</span></a> 2008
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a>Freelance Consulting for Healthcare Startup | <span> AI/ML Engineer (LLMs) </span></a> July 2023 - Present
                                                </li>
                                                <li>
                                                    <a>AgMonitor | <span> Data Scientist </span></a> 2022 - 2023
                                                </li>
                                                <li>
                                                    <a>USF - vRAD | <span> Software Development </span></a> 2021 - 2022
                                                </li>
                                                <li>
                                                    <a>Phylagen | <span> Data Science Intern</span></a> 2021 - 2021
                                                </li>
                                                <li>
                                                    <a>USF Finance | <span> ML Research Assistant</span></a> 2018 - 2020
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>  
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;
