import React, { Component } from "react";
import Scrollspy from 'react-scrollspy';
import ServiceListSocial from "../service/ServiceListSocial";

class ContactOne extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50 ptb--80">
                                <h2 className="title">Get in touch.</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <p className="description">Please feel free to contact me with any question. The best way to reach me is via email at: </p>
                                <p className="description"><a className="btn-transparent" href="mailto:theomefford@gmail.com">theo.mefford@gmail.com</a></p>
                                <p className="description">Otherwise you can find me on <a href='https://www.linkedin.com/in/temefford'>LinkedIn</a> and <a href='https://www.github.com/temefford'>Github</a>. </p>
                                <div className="col-lg-12">
                                    <ServiceListSocial item="2" column="col-lg-4 col-md-6 col-sm-6 col-12 text-center" />
                                </div> 
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactOne;