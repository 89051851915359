import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FaGithub} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderAbs from "../component/header/HeaderAbs";
import Footer from "../component/footer/FooterTwo";

const SocialShare = [
    {Social: <FaGithub /> , link: 'https://www.github.com/temefford/USF-Finance-Research'}
]

class Resume extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Resume' />

                <HeaderAbs homeLink="/" logo="symbol-dark" color="color-black" />
                {/* Start Banner Area   */}
                <div id="banner" className="fix">
                    <div className="slider-wrapper">
                        <div className="slide personal-portfolio-slider md-layout slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--29">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={'inner left'}>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Banner Area   */}

                {/* Start About Area */}
                <div id="resume" className="fix">
                    <div className="about-area pt--50 bg_color--1">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--35 align-items-center">
                                    <div className="thumbnail">
                                        <img src="/assets/images/bg/Mefford-Resume-7_23.jpg" height="2000" alt="Resume" />
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-area ptb--20 bg_color--1">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--35 align-items-center">
                                    <div className="thumbnail">
                                        <a className="rn-button-style--2 btn-solid" href="/assets/images/bg/Mefford-Resume-7_23.pdf">Download</a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            {/* End About Area */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default Resume;
