import React ,{ Component }from "react";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";


const ServiceList = [
    {Social: <FaLinkedinIn /> , title: 'LinkedIn', link: 'https://www.linkedin.com/in/temefford'},
    {Social: <FaGithub /> , title: 'Github', link: 'https://www.github.com/temefford'},
]

class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                                <div className="about-wrapper">
                                    <div className="container">
                                        <div className="row row--35 align-items-center">
                                            <div className="col-lg-5">
                                                <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                                <li><a href={`${val.link}`}>{val.Social}</a></li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-5">  
                                                <div className="row row--35 align-items-left">
                                                    <a className="btn-transparent" href={`${val.link}`}>{val.title}</a>
                                                </div> 
                                            </div>     
                                        </div>
                                    </div>
                                </div>                           
                        </div>
                        ))}
                    </div>
            </React.Fragment>
        )
    }
}


export default ServiceThree;
