import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import {FaGithub} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderAbs from "../component/header/HeaderAbs";
import Footer from "../component/footer/FooterTwo";


class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Stocks' />
                <HeaderAbs homeLink="/" logo="symbol-dark" color="color-black" />
                {/* Start Banner Area   */}
                <div id="banner" className="fix">
                    <div className="slider-wrapper">
                        <div className="slide personal-portfolio-slider md-layout slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--29">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={'inner left'}>    
                                            {/* {'<span>Theo Mefford</span>' ? <h1 className="title" dangerouslySetInnerHTML={{ __html: '<span>Theo Mefford</span>' }}></h1> : ''}  
                                             */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Banner Area   */}

                {/* Start TItle Details */}
                <div className="portfolio-area pt--120 bg_color--1">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--15">
                                        <h2>Machine Learning Guided Investing</h2>
                                    </div>      
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 {/* End Title Details */}
    
                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="thumb">
                                            <img src="/assets/images/blog/blog-01.jpg" height="150" align="right" alt="usf"/>
                                            <div className="header-btn ptb--40">
                                                <a className="rn-button-style--2 rn-btn-dark ptb--40" href="https://www.github.com/temefford/USF-Finance-Research">
                                                    <span>Source Code</span>
                                                </a>
                                            </div>
                                        </div>
                                        <h3>Overview</h3>
                                        <p className="subtitle">Our investment strategy identifies undervalued stocks based on a divergence of a company's
                                            peer-implied value estimate from its market value. We use machine learning models to predict
                                            the value of a certain stock based off of Compustat data to identify mispricing. We then track the
                                            returns for the most undervalued stocks for an investment length of one month. Further, we
                                            explore why certain models perform better than others. Previous work has only looked at the
                                            effectiveness of using a linear regression for price prediction.
                                        </p>
 
                                        <h3>Goals</h3>
                                        <div className="portfolio-view-list ptb--20" >
                                            <div className="port-view">
                                                <ul>
                                                    <h4>1. Effectively identify undervalued stocks</h4>
                                                </ul>
                                                <ul>
                                                    <h4>2. Find the model that yields the greatest returns</h4> 
                                                </ul>
                                                <ul>
                                                    <h4>3. Explore feature reduction</h4>
                                                </ul>
                                                <ul>
                                                    <h4>4. Explain the improved performance of models, such as SVR and ARD</h4>
                                                </ul>
                                            </div>
                                        </div>

                                        <h3>Specifications</h3>
                                        <div className="col-lg-12">
                                            <div className="portfolio-view-list ptb--20" >
                                                <div className="port-view">
                                                    <li><h4>Twenty three feature variables: Most important of which (determined by PCA and feature
                                                            importance analysis) relate to company income. Data Size: 293945 rows × 54 columns.</h4></li>
                                                    <li><h4>Data from these features is used to develop a model to predict the price of a stock.</h4></li>
                                                    <li><h4>Data is fed back into the model to obtain a “predicted value.”</h4></li>
                                                    <li><h4>Mispricing signal = (price predicted - actual price)/actual price</h4></li>
                                                    <li><h4>Stocks sorted into quintiles and investment goes to the most undervalued quintile. Performance (net return on initial investment) is tracked from Nov 1987 to Dec 2012.</h4></li>
                                                </div>
                                            </div>
                                        </div>

                                        <h3>Improved Model Selection</h3>
                                        <div className="col-lg-12">
                                            <div className="portfolio-view-list ptb--20" >
                                                <div className="port-view">
                                                    <li><h4>We have found that several machine learning algorithms, support vector regression (SVR) and
                                                            automatic relevance determination (ARD), perform better than linear regression. The average
                                                            monthly return on undervalued stocks for linear regression is 1.02414 whereas it is 1.02533 and
                                                        1.02525 for SVR and ARD respectively.
                                                    </h4></li>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="thumb ptb--40">
                                            <img src="/assets/images/portfolio/big/top.png" alt="Portfolio Images" height="500"/>
                                        </div>
                                        
                                        <h3>Feature Reduction</h3>
                                        <div className="col-lg-12">
                                            <div className="portfolio-view-list ptb--20" >
                                                <div className="port-view">
                                                    <li><h4>Using principal component analysis we
                                                            reduce the number of features from 23 to
                                                            12 which results in a slight improvement
                                                            in performance. The average monthly
                                                            return for LR with PCA is 1.02475.
                                                    </h4></li>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="thumb ptb--40">
                                            <img src="/assets/images/portfolio/big/pca.png" alt="Portfolio Images" height="500"/>
                                        </div>

                                        <h3>Explanation of Model Selection</h3>
                                        <div className="col-lg-12">
                                            <div className="portfolio-view-list ptb--20" >
                                                <div className="port-view">
                                                    <li><h4>We are currently exploring the reason
                                                            behind why specific models will perform better than others for this type of analysis. Based off of
                                                            the mechanics for how these models work, it makes sense that we see the results that we do.
                                                            SVR and ARD use Bayesian inference to obtain parsimonious solutions for regression. The key
                                                            feature of these is that the target function attempts to minimize the number of errors made on the
                                                            training set while simultaneously maximising the ‘margin’ between the two classes. This is an
                                                            effective ‘prior’ for avoiding over-fitting, which leads to good generalization. To back this up
                                                            analytically, we specifically identify the stocks that are different for LR and ARD in the
                                                            undervalued quintile for each month. We can explore differences in the feature variables data to
                                                            extract some understanding of why each model would choose that type of stock. This analysis is
                                                        still underway.
                                                    </h4></li>
                                                </div>
                                            </div>
                                        </div>
                                                                                        
                                        <div className="thumb ptb--40">
                                            <img src="/assets/images/portfolio/big/LR.png" alt="Portfolio Images" height="500"/>
                                        </div>
                                        
                                        <h3>Citation</h3>
                                        <p>Bartram, Sohnke and Grinblatt, Mark, “Agnostic Fundamental Analysis Works” Journal of
                                            Financial Economics, Volume 128, Issue 1, Pages 125-147, April 2018.
                                        </p>
                                        
                                        <h5>Source code can be found on my personal github:</h5>
                                        <div className="thumbnail">
                                            <a className="rn-button-style--2" href="https://www.github.com/temefford/USF-Finance-Research">Github</a>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
