import React ,{ Component }from "react";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import {Link} from "react-router-dom";

const ServiceList = [
    {
        icon: '/assets/images/service/portfolio-1.jpg',
        title: 'Discrete Event Simulation',
        description: '',
        height: "250",
        ref: '/DES'
    },
    {
        icon: '/assets/images/service/portfolio-2.jpg',
        title: 'Genomics',
        description: '',
        height: "250",
        ref: '/Genomics'
    },
    {
        icon: '/assets/images/service/portfolio-3.jpg',
        title: 'Higgs Detection',
        description: '',
        height: "250",
        ref: '/higgs'
    },
    {
        icon: '/assets/images/service/portfolio-4.jpg',
        title: 'Investment Portfolio',
        description: '',
        height: "250",
        ref: '/stocks'
    },
    {
        icon: '/assets/images/service/portfolio-5.jpg',
        title: 'Physics Papers',
        description: '',
        height: "250",
        ref: '/physics'
    }
]

class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <div className="service service__style--3">
                                <div className="thumbnail">
                                    <Link to={val.ref}>
                                        <img src={val.icon} height={val.height} alt="Service"></img>
                                    </Link>  
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>   
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
