import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import {FaGithub} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderAbs from "../component/header/HeaderAbs";
import Footer from "../component/footer/FooterTwo";

const SocialShare = [
    {Social: <FaGithub /> , link: 'https://www.github.com/temefford/'}
]
class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='DES' />
                <HeaderAbs homeLink="/" logo="symbol-dark" color="color-black" />
                {/* Start Banner Area   */}
                <div id="banner" className="fix">
                    <div className="slider-wrapper">
                        <div className="slide personal-portfolio-slider md-layout slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--29">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={'inner left'}>    
                                           {/* {'<span>Theo Mefford</span>' ? <h1 className="title" dangerouslySetInnerHTML={{ __html: '<span>Theo Mefford</span>' }}></h1> : ''}  
                                             */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Banner Area   */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">                              
                                        <h2>Discrete Event Simulation</h2>
                                        <br/>
                                        <div className="thumbnail">
                                            <a className="rn-button-style--2 btn-solid" href="https://colab.research.google.com/drive/1G42XJu26m4uWVqzKP4lPmdGlcnXTQrU-#scrollTo=WTCWsDeImdxS">Google Colab</a>
                                            <a className="pr--50"></a>
                                            <a className="rn-button-style--2 rn-btn-dark ptb--40" href="https://github.com/temefford/DES">
                                                <span>Code</span>
                                            </a> 
                                            <a className="pr--50"></a> 
                                            <a className="rn-button-style--2 btn-solid" href="https://des-image-yws742o3ja-uc.a.run.app/">Dashboard</a>
                                                                                     
                                        </div>  
                                        <p className="subtitle">
                                            This application simulates a Teleradiology network of remote radiologists analyzing medical images. It is in essence a queuing problem that can be used to explore routing algorithms (deciding who to send each new medical image to) to optimize the network's efficiency.</p>
                                        <p className="subtitle">
                                            The Colab and Github repo contain the source code that can be modified to change the experimental design and routing algorithm. 
                                        </p>
                                        <p className="subtitle">
                                            The Dash app provides an interactive UI to quickly adjust the experiment parameters 
                                            and see the effect on the network's efficiency. Select the desired system conditions in the left panel, 
                                            click the "Run Simulation" button, and the visualizations + data will be displayed for that run.
                                            </p>
                                        <p className="subtitle">
                                            In a simulation, medical images of various types are created and distributed to the radiologists in the network that are best able to process them. We have defined three tiers of medical image urgencies:
</p>
                                        <p className="subtitle">
1 - very urgent
</p>
                                        <p className="subtitle">
2 - urgent
</p>
                                        <p className="subtitle">
3 - not urgent.
</p>
                                        <p className="subtitle">
For each of the three types of image, you can define several parameters:
</p>
                                        <p className="subtitle">
Simulation Duration - This value defines the amount of time that medical images will be created and put into the system for.
</p>
                                        <p className="subtitle">
Number of Radiologists - How many radiologists are created to process the medical images. Each radiologist has their own queue that hold's the medical images waiting to be analyzed.
</p>
                                        <p className="subtitle">
Time Between Images - Images will be created at random intervals sampled from a Poisson distribution with mean defined by this interval. A smaller value means images arrive faster and create more burden on the system.
</p>
                                        <p className="subtitle">
Average Process Time - Each image type will take a certain amount of time (also random sampling around distribution with here-defined mean) once it is seen by a radiologist. This time does not start until the image has been seen by a radiologist. Once a radiologist begins analyzing an image, any other copies of the image in the network are removed from the other radiologists queues.
</p>
                                        <p className="subtitle">
Target Time - This value defines how much time is allotted for each image type to be processed (since time of creation). If not processed by this time, It is considered a "failed job."
</p>
                                        <p className="subtitle">
Fraction of specialist radiologists - In addition to an "urgency value", each medical image is given a particular "type" out of 5 and can only be seen by radiologists who can handle that type. At the definition of the radiologists, they are randomly assigned m choose n types of images that they can process. Increasing this fraction gives more radiologists more specialities so they are able to handle a larger fraction of the images.
</p>
                                        <p className="subtitle">
Cutoff Time - In the case of an overburdened system, the radiologists will be backlogged long after the Simulation Duration. This cutoff ends the simulation at a defined multiple of the Simulation Duration.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  

            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
