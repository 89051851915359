const BlogContent2 = [
    {
        image: '/assets/images/service/portfolio-1.jpg',
        category: 'USF | vRad',
        title: 'Discrete Event Simulator',
        ref: '/DES',
        description: 'This project models a telecommunications network and is used to optimize algorithms for job routing. DES is built from scratch in python to have full control.'
    },
    {
        image: '/assets/images/service/portfolio-2.jpg',
        category: 'Independent',
        title: 'ML & Genomics',
        ref: '/genomics',
        description: "Use ML models to predict cancer type from genomic data. Supervised learning using Random Forest and unsupervised learning using K-nearest neighbors."
    },
    {
        image: '/assets/images/service/portfolio-3.jpg',
        category: 'USF DS',
        title: 'Higg\'s Boson Detection',
        ref: '/higgs',
        description: 'Use ML models to search for exotic particles in high energy physics data. The goal of this work is to eliminate the need to construct features by hand.'
    },
    {
        image: '/assets/images/service/portfolio-4.jpg',
        category: 'USF Finance',
        title: 'Investment Stock Picker',
        ref: '/stocks',
        description: 'Use models to predict the value of a certain stock based off of Compustat data to identify mispricing. Construct an investment portfolio and track performance.'
    },
    {
        image: '/assets/images/service/portfolio-5.jpg',
        category: 'UMD',
        title: 'Graduate Physics Papers',
        ref: '/physics',
        description: 'These papers were written during my graduate studies in physics at the Universtiy of Maryland. The primary topics are quantum computation and machine learning.'
    }
]

export default BlogContent2;