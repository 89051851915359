import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const TestimonialOne = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Tabs>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>He effectively communicated the content of the course to us and responded whenever I had any personal questions for him during or after class. He was always willing to help.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Aklima </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Theo was one of the best TAs ive had while at college park. He cared about each students concerns and broke down hard topics into easy steps every class. I recommend you take him if you can. Very nice, cares about students, and has a good grasp of physics knowledge.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Fatima Asrafy </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Teddy was a great TA and he is very thoughtful about his students! He thought the material the best he could with the small amount of time he was allotted. He was very kind while teaching to which can go the furthest while teaching and makes it easier for the students to understand and retain the information.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Fatima Asrafy </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Theo is the best TA I have ever had. I would highly recommend Theo to anyone who is taking Physics 161 because he explains the material in a very easy to understand way. I wish I could have him as my TA for Physics 260 next semester.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Fatima Asrafy </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Held students to a great standard and made sure that the students were engaged and involved in learning. Made sure that he wasn't stressing us with coursework and gave helpful information for homework and quizzes.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Fatima Asrafy </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Best TA ever. He taught me more than my actual teacher did.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Jannat Tumpa </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Theodore Mefford helped to better understand the fundamental materials while also being engaging so that the students could both better understand the material that was being taught, but also help us to get better grades on quiz and exams. He did a great job in giving complex examples, while also explaining step by step, what needs to be done to solve the problems. Not only was he useful in teaching us how to solve specific problems, Mefford better explained the steps required to solve general problems so that we could solve any problem given to us.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Johns Due </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>The TA, Theo, was the saving grace in this class. The professor wasn't that great at teaching and if it wasn't for the great TA, I'd have done much worse.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>John Doe </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Theodore was very respectful to the students during discussion and always arrived prepared and ready for questions. I learned a lot from him because i liked his teaching style. He made his discussions student focused, meaning he focused on what the students were struggling with and emphasized important topics. Moreover, he seemed to genuinely care about how students were doing in the class, which is a great quality, that is not always found in TAs.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Amar Orthi </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>He was the best TA I've ever had.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Fatima Ma </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Theo was very good at creating a learning friendly environment in discussion each week, and did a good job in explaining material that we didn't really understand as a class.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>JON CUMMINS </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Theo gave a great teaching style for the class. I appreciated his concise overview of what we needed to know, and he prepared us well for our quizzes.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>JON CUMMINS </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                            </div>
                        </div>
                    </TabPanel> 
                    <TabList className="testimonial-thumb-wrapper">
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-5.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-6.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-7.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-8.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                    </TabList>

                </Tabs>
            </div>
        </div>
    )
}

export default TestimonialOne
