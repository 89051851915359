import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import {FaGithub} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderAbs from "../component/header/HeaderAbs";
import Footer from "../component/footer/FooterTwo";

const SocialShare = [
    {Social: <FaGithub /> , link: 'https://www.github.com/temefford/Graduate-Physics-Papers'}
]

class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Physics' />
                <HeaderAbs homeLink="/" logo="symbol-dark" color="color-black" />
                {/* Start Banner Area   */}
                <div id="banner" className="fix">
                    <div className="slider-wrapper">
                        <div className="slide personal-portfolio-slider md-layout slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--29">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={'inner left'}>    
                                           {/* {'<span>Theo Mefford</span>' ? <h1 className="title" dangerouslySetInnerHTML={{ __html: '<span>Theo Mefford</span>' }}></h1> : ''}  
                                             */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Banner Area   */}

                {/* Start Header Details */}
                <div className="portfolio-area pt--120 bg_color--1">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--15">
                                        <h2>Detecting the Higgs Boson using ML</h2>
                                    </div>      
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Header Details */}
                
                {/* Start Body Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">                                   
                                        <p className="subtitle"></p>
                                        <span>
                                            <p><embed src="/assets/images/portfolio/Higgs.pdf" width="100%" height="1000"></embed></p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Body Details */}

               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
