import React ,{ Component }from "react";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";


const ServiceList = [
    {
        icon: '/assets/images/icons/ml-icon.png',
        title: 'AI & LLMs',
        description: 'I build products with open source foundation models, such as Falcon7b, by fine-tuning LoRa layers with custom data and implementing vector embedding into the LLM architecture. ',
        height: "250"
    },
    {
        icon: '/assets/images/icons/ds-icon.png',
        title: 'Data Science & Machine Learning',
        description: 'I became interested in data science in 2015 and have been implementing data science tools and methodology in my approach to problem solving ever since then. ',
        height: "250"
    },
    {
        icon: '/assets/images/icons/network2-icon.png',
        title: 'Network Science',
        description: 'In addition to quantum theory, non-linear dynamics and chaos theory were my primary fields of interest and research as a physicist. Network science is a natural corollory to these fields and allows us to measure, model, predict and visualize meaningful interactions and interconnectivity of social, physical and technological systems. I think the machine learning, network science and high-throughput sequencing will revolutionize our understanding of the human body and path the way to precision healthcare.',
        height: "250"
    },
    {
        icon: '/assets/images/icons/teach-icon.png',
        title: 'Teaching',
        description: 'Education is extremely important to me. I greatly value my own education as well as my ability to aid in other\'s education. At UMD I lectured and led discussions for first and second year undergraduate physics courses. I also filled a temporary role teaching high school science while a teacher was away on maternity leave.' ,
        height: "250"
    },
    {
        icon: '/assets/images/icons/db-icon.png',
        title: 'MLOps & Data Engineering',
        description: 'End-to-end development of data science and AI projects incorporating interactive, interpretable data visualizations and dashboards.',
        height: "250"
    },
    {
        icon: '/assets/images/icons/webdev-icon.png',
        title: 'Web Development',
        description: 'While model building is my primary interest and skill, I enjoy building products end-to-end. I\'ve built websites using ReactJS and Vue. I\'m also developing some projects in React Native.',
        height: "250"
    }
]

class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <div className="about-area ptb--40  bg_color--1">
                                <div className="about-wrapper">
                                    <div className="container">
                                        <div className="row row--15 align-items-left">
                                            <div className="col-lg-3"> 
                                                <div className="icon">
                                                    <img height="100" src={val.icon} alt="About Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className={`inner text-left`}>   
                                                    <h4 className="title">{val.title}</h4>
                                                    {val.description} 
                                                </div>
                                            </div>     
                                        </div>     
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}


export default ServiceThree;
