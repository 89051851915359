const BlogContent = [
    {
        images: '01',
        title: ' University of San Francisco',
        category: 'MS Data Science (2023), MBA (2024)'
    },
    {
        images: '02',
        title: 'University of Maryland ',
        category: 'MS Physics (2018)'
    },

    {
        images: '03',
        title: 'Santa Clara University',
        category: 'BS Physics and Mathematics (2013)'
    },
    {
        images: '04',
        title: 'Piedmont High School',
        category: 'High School Diploma'
    },
    {
        images: '05',
        title: 'A big ticket gone to be an interesting ',
        category: 'Management'
    },
    {
        images: '06',
        title: 'The Home of the Future Could Bebes',
        category: 'Design'
    },
]

export default BlogContent;